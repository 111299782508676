import { Card, CardContent, Typography, Alert, Button, Box, CircularProgress } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import TvIcon from '@mui/icons-material/Tv';
import React, { useContext, useEffect, useState } from 'react';
import { reenvioPacoteFxsRequest, reenvioPacoteIptvRequest } from '../../../../services/apiSys';
import { useParams } from 'react-router-dom';
import { GetCertContext } from '../../../../hooks/useGetCertContext';
import { ReenvioFxsResponse } from '../../../../interfaces/ReenvioFxsResponse';
import { ReenvioIptvResponse } from '../../../../interfaces/ReenvioIptvResponse';

type Props = {
};

const TabReenvioPacotes = (props: Props) => {

  const { value } = useParams();
  const { cert, getCert, loading } = useContext(GetCertContext);

  const [loadingVoip, setLoadingVoip] = useState(false);
  const [loadingIptv, setLoadingIptv] = useState(false);
  const [respVoip, setRespVoip] = useState<ReenvioFxsResponse>();
  const [respIptv, setRespIptv] = useState<ReenvioIptvResponse>();

  useEffect(() => {
    if (!cert) {
      getCert(value);
    }
  }, [cert, getCert, value]);

  const handleFxs = async () => {
    try {
      setLoadingVoip(true);
      const resp = await reenvioPacoteFxsRequest({ designador: cert!.dadosAtividade.designador });
      setRespVoip(resp);
    } catch (error) {
      return error;
    } finally {
      setLoadingVoip(false);
    }
  };

  const handleIptv = async () => {
    try {
      setLoadingIptv(true);
      const resp = await reenvioPacoteIptvRequest({ designador: cert!.dadosAtividade.designador });
      setRespIptv(resp);
    } catch (error) {
      return error;
    } finally {
      setLoadingIptv(false);
    }
  };

  return cert ? (
    <Box>
      {/* Reenvio Voip */}
      <Card
        className='card'
        style={{ marginTop: "2em", backgroundColor: "#f5f5f5" }}
        sx={{
          '& .MuiTextField-root': { m: 1 },
        }}
      >
        <CardContent sx={{ display: 'flex', m: 0 }}>
          <CallIcon sx={{ fontSize: 50, marginRight: 1 }} />
          <Box display={'flex'} width={'100%'} justifyContent={'space-between'} >
            <Typography>Reenvio FxS/VoiP</Typography>
            <Box justifyItems={'center'}>
              {respVoip && (
                <Alert severity={respVoip.status === 'passed' ? 'success' : 'error'}>{respVoip.recommendation}</Alert>
              )}
              {!loadingVoip ? (
                <Button
                  className='button'
                  sx={{ marginTop: 1 }}
                  onClick={handleFxs}
                >
                  Aplicar Comando
                </Button>
              ) : (
                <CircularProgress />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      {/* Reenvio IPTV */}
      <Card
        className='card'
        style={{ marginTop: "2em", backgroundColor: "#f5f5f5" }}
        sx={{
          '& .MuiTextField-root': { m: 1 },
        }}
      >
        <CardContent sx={{ display: 'flex' }}>
          <TvIcon sx={{ fontSize: 50, marginRight: 1 }} />
          <Box display={'flex'} width={'100%'} justifyContent={'space-between'} >
            <Typography>Reenvio Pacote IPTV</Typography>
            <Box justifyItems={'center'}>
              {respIptv && (
                <Alert severity={respIptv.status === "passed" ? 'success' : 'error'}>{respIptv.recommendation}</Alert>
              )}
              {!loadingIptv ? (
                <Button
                  className='button'
                  sx={{ marginTop: 1 }}
                  onClick={handleIptv}
                >
                  Aplicar Comando
                </Button>
              ) : (
                <CircularProgress />
              )}

            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  ) : (
    <Box display={'flex'} justifyContent={'center'}>
      {loading && <CircularProgress />}
    </Box>
  );
};

export default TabReenvioPacotes;