import { ComponentProps } from "react"

import styles from "./Footer.module.css";

interface FooterProps extends ComponentProps<"footer">{

}

const Footer = (props: FooterProps) => {
  return (
    <>
    <footer className={styles.footer} {...props} >
    <hr style={{ margin: "auto 0 0", width: "99%" }} />
      <span>
        &copy;<p> MagicTool - Centro De Desenvolvimento De Dispositivos </p>
      </span>
    </footer>
    </>
  );
};

export default Footer;