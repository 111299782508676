import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Certfication from './Certification/Certfication';
import MapDisp from './mapDisp/MapDisp';
import CoberturaWifi from './CoberturaWifi/CoberturaWifi';
import Alerts from './Alerts/Alerts';

//interfaces
import { useParams } from 'react-router-dom';
import { AlertsRequest, InterfaceCoberturaRequest, StationsCoberturaRequest } from '../../../services/apiHac';
import { MapDevices } from '../../../services/apiHac';
import { Root } from '../../../interfaces/DeviceMap';
import { AlertsInterfaceData } from "../../../interfaces/AlertsInterface";


import { Alert, Button, CircularProgress } from "@mui/material";
import { AliasCobertura } from '../../../interfaces/ScoreInterface';
import { GetCertContext } from "../../../hooks/useGetCertContext";
import { ProfileOltPsaResponse } from '../../../interfaces/ProfileOltPsaResponse';
import { StatusConfig } from '../../../enum/EnumStatusConfig';
import { ProfileMapping } from '../../../enum/EnumProfileMapping';
import { ProfileOltPsaRequest } from '../../../services/apiSys';
import TabReenvioPacotes from './ReenvioPacote/TabReenvioPacotes';
import TabConfigRede from './ConfigRede/TabConfigRede';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, padding: "4px" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [tabValue, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { value: serial } = useParams();
  const { cert } = React.useContext(GetCertContext);

  const [deviceMap, setDeviceMap] = React.useState<Root | undefined>();
  const [alertsCoverage, setAlertsCoverage] = React.useState<AlertsInterfaceData | undefined>();
  const [coberturaInterface, setCoberturaInterface] = React.useState<AliasCobertura | undefined>();
  const [coberturaStation, setCoberturaStation] = React.useState<AliasCobertura>();
  const [ProfileOltPsaResponse, setProfileOltPsaResponse] = React.useState<ProfileOltPsaResponse>();
  const [loadingDeviceMap, setLoadingDeviceMap] = React.useState<boolean>(false);
  const [loadingAlerts, setLoadingAlerts] = React.useState<boolean>(false);
  const [loadingCobertura, setLoadingCobertura] = React.useState<boolean>(false);
  const [loadingProfileOltPsa, setLoadingProfileOlt] = React.useState<boolean>(false);

  const getDevicesMapped = async (id: string) => {

    setLoadingDeviceMap(true);
    try {
      const res = await MapDevices(id);
      setDeviceMap(res);
    } catch (error: any) {
      return;
    } finally {
      setLoadingDeviceMap(false);
    }
  };

  const getAlerts = async (id: string) => {
    setLoadingAlerts(true);
    try {
      const res = await AlertsRequest(id);
      setAlertsCoverage(res);

    } catch (error: any) {
      return;
    } finally {
      setLoadingAlerts(false);
    }
  };

  const requestsCobertura = async (id: string) => {

    setLoadingCobertura(true);
    try {
      const resCoberturaInterface = await InterfaceCoberturaRequest(id);
      setCoberturaInterface(resCoberturaInterface);

      const resCoberturaScore = await StationsCoberturaRequest(id);
      setCoberturaStation(resCoberturaScore);
    } catch (error) {
      return;
    } finally {
      setLoadingCobertura(false);
    }
  };

  const handleProfileOltPsa = async () => {
    setProfileOltPsaResponse(undefined);
    if (cert) {
      setLoadingProfileOlt(true);
      try {
        const res = await ProfileOltPsaRequest({ designador: cert.dadosAtividade.designador });
        setProfileOltPsaResponse(res);
      } catch (error) {
        // setProfileOltPsaResponse({ status: 'error', description: 'Falha no processamento.' });
        return;
      } finally {
        setLoadingProfileOlt(false);
      }
    }
  };

  React.useEffect(() => {
    if (serial) {
      getAlerts(serial);
      getDevicesMapped(serial);
      requestsCobertura(serial);
    }
  }, [serial]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="CERTIFICADO BANDA LARGA" sx={{ padding: "2px 10px" }} {...a11yProps(0)} />
          <Tab label="MAPA DE DISPOSITIVOS" sx={{ padding: "2px 10px" }} {...a11yProps(1)} />
          <Tab label="COBERTURA WI-FI" sx={{ padding: "2px 10px" }} {...a11yProps(2)} />
          <Tab label="ALERTAS" sx={{ padding: "2px 10px" }} {...a11yProps(3)} />
          <Tab label="PROFILE OLT/PSA" sx={{ padding: "2px 10px" }} {...a11yProps(4)} />
          <Tab label="CONFIGURAÇÃO DE REDE" sx={{ padding: "2px 10px" }} {...a11yProps(5)} disabled />
          <Tab label="REENVIO PACOTES" sx={{ padding: "2px 10px" }} {...a11yProps(6)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <Certfication loadingDeviceMap={loadingDeviceMap} dhcpTable={deviceMap?.values.dhcp_table} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        {!loadingDeviceMap ?
          <MapDisp certDeviceMap={deviceMap} />
          :
          <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        {!loadingCobertura ?
          <CoberturaWifi scoreInterface={coberturaInterface} scoreStation={coberturaStation} />
          :
          <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        {!loadingAlerts ?
          <Alerts alertsCoverage={alertsCoverage} />
          :
          <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
        {ProfileOltPsaResponse &&
          ProfileOltPsaResponse.actions.map((action) => {
            return (
              <Box display={'flex'} justifyContent={'center'} marginBottom={2}>
                <Alert severity={StatusConfig[action.status as keyof typeof StatusConfig]}>{
                  `${ProfileMapping[action.id as keyof typeof ProfileMapping]} ${action.recommendation ? `- ${action.recommendation}` : ''}`
                }
                </Alert>
              </Box>
            );
          })
        }
        <Box display={'flex'} justifyContent={'center'}>
          <Button
            variant='contained'
            className='button'
            onClick={handleProfileOltPsa}
            disabled={loadingProfileOltPsa}
          >
            Validar e configurar Profile OLT/PSA
          </Button>
        </Box>
        {loadingProfileOltPsa &&
          <Box sx={{ display: 'flex', marginTop: "5em", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={5}>
        <TabConfigRede />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={6}>
        <TabReenvioPacotes />
      </CustomTabPanel>
    </Box>
  );
}