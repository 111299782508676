import React, { useContext, useEffect, useState } from 'react';
import { GetCertContext } from '../../../../hooks/useGetCertContext';
import { Button, Typography, CircularProgress, Card, CardContent, Alert, Divider, Grid, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import DynamicView from '../../../../components/DynamicView';
import { NetConfigHistoricResponse } from '../../../../interfaces/NetConfigHistoricResponse';
import { NetConfigResponse } from '../../../../interfaces/NetConfigResponse';
import { LastNetConfigsRequest, networkConfigRequest } from '../../../../services/apiSys';
import { InputCustom } from '../../../../interfaces/InputCustom';
import { useParams } from 'react-router-dom';

type Props = {};

const AlarmOltLabelDisplay: Record<string, InputCustom> = {
  hostname: { label: 'Hostname' },
  sncliente: { label: 'sncliente' },
  ipolt: { label: 'ipolt' },
  redeacesso: { label: 'redeacesso' },
  vendor: { label: 'vendor' },
  slot: { label: 'slot' },
  porta: { label: 'porta' },
  spliter1n: { label: 'spliter1n' },
  cabo: { label: 'cabo' },
  fibra: { label: 'fibra' },
  armario: { label: 'armario' },
};

const TabConfigRede = (props: Props) => {

  const { value } = useParams();
  const { cert, getCert, loading } = useContext(GetCertContext);

  const [loadingNetConfig, setLoadingNetConfig] = useState<boolean>(false);
  const [netConfigResponse, setNetConfigResponse] = useState<NetConfigResponse>();
  const [netConfigHistoric, setNetConfigHistoric] = useState<NetConfigHistoricResponse[]>();
  const [open, setOpen] = useState(false);
  const [selectedCheckListVlan, setSelectedCheckListVlan] = useState<any>();

  useEffect(() => {
    if (!cert) {
      getCert(value);
    } else {
      getLastNetConfigs();
    }
  }, [cert]);

  const getLastNetConfigs = async () => {
    if (cert) {
      try {
        const res = await LastNetConfigsRequest(cert.dadosAtividade.designador);
        setNetConfigHistoric(res);
      } catch (error) {
        return;
      }
    }
  };

  const handleNetConfig = async () => {
    setNetConfigResponse(undefined);
    if (cert) {
      setLoadingNetConfig(true);
      try {
        const res = await networkConfigRequest({ designador: cert.dadosAtividade.designador });
        setNetConfigResponse(res);
      } catch (error) {
        return;
      } finally {
        setLoadingNetConfig(false);
        getLastNetConfigs();
      }
    }
  };

  const openModal = (id: string) => {
    setOpen(true);
    console.log(`Abrir Modal ID: ${id}`);
    setSelectedCheckListVlan(id);
  };

  const handleClose = (): void => {
    throw new Error('Function not implemented.');
  };

  return cert ? (
    <>
      <Grid container spacing={2} marginTop={1}>
        {/* Button Grid */}
        <Grid item xs={7} height={'50em'}>
          <Box display={'flex'} justifyContent={'center'}>
            <Button
              variant='contained'
              className='button'
              onClick={handleNetConfig}
              disabled={loadingNetConfig}
            >
              Iniciar Configuração
            </Button>
          </Box>
          {loadingNetConfig &&
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: "5em", justifyContent: "center", alignItems: 'center' }}>
              <Typography>Configurando Rede</Typography>
              <CircularProgress />
            </Box>
          }
          {netConfigResponse &&
            <Card className='card' style={{ marginTop: "2em", backgroundColor: "#f5f5f5" }}
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
            >
              <CardContent>
                {netConfigResponse.alarmOlt.status === 200 ? (
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Alert severity='success' icon={false} variant='filled'>OLT OK</Alert>
                  </Box>
                ) : (
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Alert severity='error' icon={false} variant='filled'>OLT NOK</Alert>
                  </Box>
                )
                }
                <DynamicView data={netConfigResponse.alarmOlt} labelMap={AlarmOltLabelDisplay} variant='standard' />
              </CardContent>
            </Card>
          }
        </Grid>
        <Divider orientation='vertical' flexItem sx={{ mr: "-1px" }} />
        {/* Executions Grid */}
        <Grid item xs={5} >
          <Typography>Configurações Recentes</Typography>
          <Box display={'flex'} flexDirection={'column'}>

            {netConfigHistoric && netConfigHistoric.map((config) => {
              return (
                <Card key={config.id} onClick={() => openModal(config.id)} variant='outlined' sx={{ borderRadius: "20px", backgroundColor: "#f5f5f5", marginBottom: "0.5em", cursor: 'pointer' }}>
                  <CardContent style={{ padding: "0.5em 1em" }}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography fontWeight={"bold"}>
                        {`ID: ${config.id}`}
                      </Typography>
                      {config.status === 'in progress' &&
                        // <Status status='warning' msg='em andamento' />
                        <Alert sx={{ padding: "2px 2px" }} icon={false} severity='warning' variant='filled'>Em Andamento</Alert>
                      }
                      {config.status === 'success' &&
                        // <Status status='warning' msg='em andamento' />
                        <Alert sx={{ padding: "2px 2px" }} icon={false} severity='success' variant='filled'>Concluído</Alert>
                      }
                      {config.status === 'failed' &&
                        // <Status status='warning' msg='em andamento' />
                        <Alert sx={{ padding: "2px 2px" }} icon={false} severity='error' variant='filled'>Falha de conectividade</Alert>
                      }
                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography>
                        {config.action}
                      </Typography>
                      <Typography variant='subtitle2'>
                        Execução: {new Date(config.timestamp).toLocaleString("pt-BR")}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        </Grid>
      </Grid>

      <Dialog onClose={() => setOpen(false)} open={open} >
        <DialogTitle>Detalhes do Check List VLAN</DialogTitle>
        <DialogContent>
          {selectedCheckListVlan && (
            <>
              <Typography>{selectedCheckListVlan}</Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <Box display={'flex'} justifyContent={'center'}>
      {loading && <CircularProgress />}
    </Box>
  );
};

export default TabConfigRede;