// react
import { useEffect, useState } from 'react'

//hooks
import { useNavigate, useParams } from 'react-router-dom';

// styles
import styles from "./Search.module.css"

// external
import 'dayjs/locale/de';
import dayjs, { Dayjs } from 'dayjs';

//assets
import hguImage from "../../assets/cpes/1_geral.png"
import hgu6 from "../../assets/cpes/Hgu6-Frente.png"
import DateFormated from '../ReturnDate/DateFormated';
import { IpLightListData } from '../../interfaces/IPLightList';
import { wifi6List } from '../../hooks/useGetCertContext';


enum Produtos {
  todosProdutos = 'Produtos' as any,
  remoteMagic = 'MagicTool Remoto' as any,
  certWifi = 'Certificados Wi-Fi' as any,
  certBandWidth = 'Certificados Banda Larga' as any,
}

enum Status {
  todos = 'Status' as any,
  grave = 'Grave' as any,
  alerta = 'Alerta' as any,
  moderado = 'Moderado' as any,
  leve = 'Leve' as any,
}


interface Props {
  response: IpLightListData[] | undefined;
}

const SearchList = ({ response }: Props) => {

  useEffect(() => {

  }, [response]);

  const [products, setProducts] = useState<Produtos>(Produtos.todosProdutos);
  const [status, setStatus] = useState<Status>(Status.todos);
  const [queryValue, setQueryValue] = useState<string>('');
  const [dateIsVisible, setDateIsVisible] = useState<boolean>(false)
  const [dateIsVisibleEnd, setDateIsVisibleEnd] = useState<boolean>(false)
  const [dateInit, setDateInit] = useState<Dayjs | null>(dayjs())
  const [initialDateFilter, setInitialDateFilter] = useState<string>()
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs())
  const [endDateFilter, setEndDateFilter] = useState<string>()

  const { filter, value } = useParams()

  const handleChangeDate = (newDate: Dayjs | null) => {
    if (newDate) {
      setDateInit(newDate)
      setInitialDateFilter(newDate.format("DD/MM/YYYY"))
    }
    setDateIsVisible(false)
  }


  const handleChangeDateEnd = (newDate: Dayjs | null) => {
    if (newDate) {
      setDateEnd(newDate)
      setEndDateFilter(newDate.format("DD/MM/YYYY"))
    }
    setDateIsVisibleEnd(false)
  }

  const navigate = useNavigate()

  const listFiltered = response?.filter(filtro => filtro.designador.includes(queryValue)
    || filtro.modelo.includes(queryValue)
    || filtro.serial_number.includes(queryValue)
    || filtro.product.includes(products.toString())
  )

  const handlePage = (page: string) => {
    if (filter) {
      switch (filter) {
        case "remoteMagic":
          navigate(`/servicos-produtos/hac/remoteMagic/${page}`)
          break

        case "certWifi":
          navigate(`/servicos-produtos/wi-fi/certWifi/${page}`)
          break

        case "certBandWidth":
          navigate(`/servicos-produtos/banda-larga/certBandWidth/${page}`)
          break
      }
    }
  }

  return (
    <>
      {response &&
        <div className={styles.div_table}>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>PRODUTO</th>
                <th>FIRMWARE</th>
                <th>MODELO</th>
                <th>DESIGNADOR</th>
                <th># SERIAL</th>
                <th>LOG</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {listFiltered &&
                listFiltered.map((item, index) => (
                  <tr key={index}
                    className={styles.item_list}
                    onClick={() => handlePage(item.id ? item.id : item.serial_number)}
                  >
                    <td>
                      {wifi6List.includes(item.modelo) ?
                        <img src={hgu6} alt="HGU" /> :
                        <img src={hguImage} alt="HGU" />
                      }
                    </td>
                    <td>{item.product}</td>
                    <td className={styles.firmware}>{item.firmware}</td>
                    <td>{item.modelo}</td>
                    <td>{item.designador}</td>
                    <td>{item.serial_number}</td>
                    <td><DateFormated dateGmt={3} date={item.sent_time} /></td>
                    {/* <td className={index % 2 == 0 ? styles.last_td : styles.last_td_red}></td> */}
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      }
      {response?.length === 0 || !response ? <>
        <h1 style={{ textAlign: "center" }}>
          Parece que não foram encontrado nenhum resultado em sua pesquisa. <br />
          Tente novamente com outro dispositivo ou serviço.
        </h1>
      </>
        : !response && value === undefined &&
        <>
          <h1 style={{ textAlign: "center" }}>
            Comece fazendo a busca de algum dispositivo pelo número de serial  !
          </h1>
        </>}
    </>
  )
}

export default SearchList