// hook
import { useParams } from 'react-router-dom';

// css
import styles from "./NewHac.module.css";

// content
import BasicTabs from './content/TabNav';

// components
import Navbar from '../../components/Navbar';
import Aside from './content/Aside';
import SubNavBar from '../../components/sub-navbar/SubNavBar';

const NewHac = () => {

  return (
    <div className={styles.container}>
      {/* <SubNavBar /> */}
      <main className={styles.main}>
        <BasicTabs />
        <Aside />
      </main>
    </div>
  );
};

export default NewHac;